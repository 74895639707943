import * as Y from 'yjs';
import { Transaction, YEvent, YMapEvent } from 'yjs';
import { sid } from '@xspecs/short-id';
import { logger } from '@xspecs/logger';
import { ApplicationContext } from '../ApplicationContext';
import { isTransient } from './Transient';
import { isLocal } from './LocalStoreProvider';
import { EntityVersionUpcastRegistry } from './EntityVersionUpcastRegistry';
import { EntityUpcaster } from './EntityUpcaster';
import { SerializedEntity, SerializedEntityChanges } from '../types';
import { NotificationTypes } from '../observable/SingleSourceObserver';
import { RecalculateDimensionsCommand } from '../commands/dimensions/RecalculateDimensionsCommand';
import { EntityType } from '../entities/EntityType';
import { ToggleScriptVisibilityCommand } from '../commands/scripts/ToggleScriptVisibilityCommand';
import { ModelContext } from '../ModelContext';
import { EntityParserFactory } from '../entities/EntityParserFactory';
import { ConstructBase } from '../entities/constructs/ConstructBase';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

export enum TransactionType {
  Local = 'local',
  Remote = 'remote',
}

export class ModelYjsAdaptor {
  private readonly localChangeTracker: { [key: string]: string[] } = {};
  private readonly userActionOrigin: string = sid();
  private readonly initialLoadCallback: () => void = () => {};
  private undoManager: Y.UndoManager;
  private entityVersionRegistry: EntityVersionUpcastRegistry;
  private readonly applicationState = ApplicationContext.getInstance();
  private disabled: boolean = false;
  private isE2EMode = typeof window !== 'undefined' ? localStorage.getItem('E2E_MODE') === 'true' : false;
  private fileId: string;

  private constructor(
    private readonly modelContext: ModelContext,
    private readonly storageMap: Y.Map<any>,
    private readonly settingsMap: Y.Map<any>,
    initialLoadCallback?: () => void,
  ) {
    if (initialLoadCallback) this.initialLoadCallback = initialLoadCallback;
    this.initialize();
  }

  static instance: ModelYjsAdaptor | null = null;

  public static getInstance(
    modelContext: ModelContext,
    storageMap: Y.Map<any>,
    settingsMap: Y.Map<any>,
    initialLoadCallback?: () => void,
  ): ModelYjsAdaptor {
    if (!this.instance) {
      this.instance = new ModelYjsAdaptor(modelContext, storageMap, settingsMap, initialLoadCallback);
      if (isBrowser) {
        window['model'] = this.instance.modelContext;
        window['adaptor'] = this.instance;
        window['undo'] = this.instance.undo.bind(this.instance);
      }
    }
    return this.instance;
  }

  public static dispose() {
    if (this.instance) {
      this.instance.dispose();
      this.instance = null;
    }
  }

  public undo() {
    this.undoManager.undo();
  }

  public redo() {
    this.undoManager.redo();
  }

  private initialize() {
    // ToDo: move this out to composite root
    const entityVersionUpcastRegistry = new EntityVersionUpcastRegistry(
      new EntityUpcaster(),
      this.storageMap,
      this.modelContext,
      this.applicationState.messageBus,
    );
    this.fileId = this.modelContext.modelFile.fileId;
    entityVersionUpcastRegistry.register();
    this.entityVersionRegistry = entityVersionUpcastRegistry;

    this.undoManager = new Y.UndoManager([this.storageMap], {
      trackedOrigins: new Set([this.userActionOrigin]),
    });
    let initialLoadHandled = false;
    // let callCount = 0;
    this.storageMap.observeDeep((events, transaction) => {
      this.onStorageMapChange(events, transaction);
      // console.log('SingleSourceModeYjsAdaptor.onStorageMapChange called', callCount++);
      if (!initialLoadHandled) {
        initialLoadHandled = true;
        this.modelContext.isLoaded = true;
        if (this.initialLoadCallback) {
          this.initialLoadCallback();
        }
      }
    });
    // this.fileTreeMap.observeDeep((events, transaction) => {
    //   this.onFileTreeMapChange(events, transaction);
    // });
    // clear old items from transient map
    this.applicationState.observer.subscribe(
      NotificationTypes.OnSave,
      this.onSave.bind(this),
      this.modelContext.modelFile.fileId,
    );
    this.applicationState.observer.subscribe(
      NotificationTypes.OnUndo,
      () => this.undo(),
      this.modelContext.modelFile.fileId,
    );
    this.applicationState.observer.subscribe(
      NotificationTypes.OnRedo,
      () => this.redo(),
      this.modelContext.modelFile.fileId,
    );

    //this.undoManager.on('stack-item-popped', (event) => this.onUndoManagerStackItemPopped(event));
  }

  private arraysAreEqual(arr1: string[], arr2: string[]): boolean {
    return arr1.length === arr2.length && [...arr1].sort().every((value, index) => value === [...arr2].sort()[index]);
  }

  private compareVersions(a: string | undefined, b: string): number {
    const aParts = (a ?? '0.0.0').split('.').map(Number);
    const bParts = b.split('.').map(Number);

    for (let i = 0; i < 3; i++) {
      const diff = (aParts[i] || 0) - (bParts[i] || 0);
      if (diff !== 0) return diff;
    }
    return 0;
  }

  private onStorageMapChange(events: YEvent<any>[], transaction: Transaction): void {
    if (this.disabled) return;
    const changes: SerializedEntityChanges = { added: [], updated: [], deleted: [] };
    const updatesMap = {};
    events.forEach((event: YMapEvent<any>) => {
      event.changes.keys.forEach((change, key) => {
        if (change.action === 'delete') {
          // there isn't a nice way to get the deleted item so had to use this as size of the YMap is 0 even though it contains the data!!
          let deletedItemType = change?.oldValue?._map?.get('type')?.content['arr'];
          let deletedItemScope = change?.oldValue?._map?.get('scopes')?.content['arr'];
          if (Array.isArray(deletedItemType)) deletedItemType = deletedItemType[0];
          if (Array.isArray(deletedItemScope)) deletedItemScope = deletedItemScope[0];
          changes.deleted.push({ id: key, type: deletedItemType });
        } else {
          const entity = this.storageMap.get(key)?.toJSON?.() ?? this.storageMap.get(key);
          //entity = this.upcastToLatestVersion(entity, event, key);
          const id = event.target.get('id');
          if (change.action === 'add') this.applyAddButMaybeUpdate(id, updatesMap, key, changes, event, entity);
          else if (change.action === 'update') this.applyUpdateChange(id, updatesMap, key, event);
        }
      });
    });
    changes.updated = Object.values(updatesMap);
    if (this.entityVersionRegistry.entityUpcaster.hasAfterUpcastProcessing()) {
      this.entityVersionRegistry.entityUpcaster.afterUpcastsProcessing(this.storageMap);
    }
    this.applyMapChanges(transaction.origin, changes);
    this.entityVersionRegistry.entityUpcaster.afterModelLoaded();
  }

  private applyAddButMaybeUpdate(
    id: string,
    updatesMap: any,
    key: string,
    changes: SerializedEntityChanges,
    event: YMapEvent<any>,
    entity: SerializedEntity,
  ): void {
    if (id) {
      // this means a field was added - which is an update to an entity! WTF!
      this.applyUpdateChange(id, updatesMap, key, event);
    } else {
      if (entity && Object.keys(entity).length > 0) {
        if (this.entityVersionRegistry.entityUpcaster.isRetiredEntity(entity)) {
          //logger.error('Item to upcast is retired', JSON.stringify(entity, null, 2));
          return;
        }
        if (
          this.compareVersions(entity.version, '2.0.4') < 0 &&
          EntityParserFactory.isInstanceOfBase(entity.type, ConstructBase)
        ) {
          const entity = this.storageMap.get(key)?.toJSON?.() ?? this.storageMap.get(key);
          if (entity.attributes && entity.attributes.fontSize) {
            entity.version = ConstructBase.version;
            entity.attributes.fontSize = -1;
          }
          this.storageMap.doc!.transact(() => {
            this.storageMap.set(entity.id, new Y.Map(this.getFields(entity)));
          }, TransactionType.Remote);
        }
        if (this.entityVersionRegistry.entityUpcaster.hasUpcaster(entity)) {
          this.disabled = true;
          this.removeNonWorkspaceEntitiesOnce();
          const upcastEntities = this.entityVersionRegistry.entityUpcaster.upcast(entity)!;
          upcastEntities.forEach((upcastEntity) => {
            if (upcastEntity && Object.keys(upcastEntity).length > 0) {
              const existingEntity = changes.added.find((addedEntity) => addedEntity.id === upcastEntity.id);
              if (existingEntity) {
                changes.added = changes.added.map((addedEntity) =>
                  addedEntity.id === upcastEntity.id ? upcastEntity : addedEntity,
                );
              } else {
                changes.added.push(upcastEntity);
              }
              this.storageMap.doc!.transact(() => {
                this.storageMap.set(upcastEntity.id, new Y.Map(this.getFields(upcastEntity)));
              }, TransactionType.Remote);
            }
          });
          this.disabled = false;
        } else {
          changes.added.push(entity);
        }
      } else {
        logger.error('Item to update not found in storage map or is empty', JSON.stringify(entity, null, 2));
        this.storageMap.delete(id);
      }
    }
  }

  private applyUpdateChange(id: string, map: any, key: string, event: YMapEvent<any>) {
    this.initializeMap(id, map, key);
    if (id)
      map[id][key] = this.getValue(event, key); // local changes
    else map[key] = this.getValue(event, key); // remote changes
  }

  // private applyUpdateChange(id: string, map: any, key: string, entity: any) {
  //   this.initializeMap(id, map, key);
  //   if (id) map[id][key] = this.getValueFromEntity(entity, key); // local changes
  //   else map[key] = this.getValueFromEntity(entity, key); // remote changes
  // }

  private getValueFromEntity(entity: any, key: string) {
    return entity[key];
  }

  private getValue(event: YMapEvent<any>, key: string) {
    // TODO Rami is this going to break shit?!
    return event.target.get(key)?.toJSON?.() ?? event.target.get(key);
  }

  private initializeMap(id: string, updatesMap: any, key: string) {
    if (!updatesMap[id ?? key]) updatesMap[id ?? key] = {};
  }

  private applyMapChanges(transactionOrigin: string, changes: SerializedEntityChanges) {
    const mapChanges = [...changes.added, ...changes.updated, ...changes.deleted]
      .filter((item) => item)
      .map((item) => item.id);
    const jsonChanges = JSON.parse(JSON.stringify(changes));
    if (this.isLocalMapChange(transactionOrigin, mapChanges)) {
      if (!this.modelContext.validateChanges(jsonChanges)) {
        logger.error('SingleSourceModelYjsAdaptor:applyMapChanges: Rolling back changes');
        setTimeout(() => this.undo(), 250);
        this.undo();
      }
      delete this.localChangeTracker[transactionOrigin];
    } else {
      this.modelContext.entities.applyRemoteChanges(jsonChanges);
    }
  }

  private isLocalMapChange(transactionOrigin: string, mapChanges: any[]) {
    if (transactionOrigin === TransactionType.Local) {
      return true;
    }
    return (
      this.localChangeTracker[transactionOrigin] &&
      transactionOrigin &&
      this.arraysAreEqual(mapChanges, this.localChangeTracker[transactionOrigin])
    );
  }

  private onSave(changes: { added: any[]; updated: any[]; deleted: any[] }): void {
    this.localChangeTracker[this.userActionOrigin] = Object.values(changes).flatMap((change) =>
      change.map((entity) => entity.id),
    );
    try {
      this.storageMap.doc!.transact(() => {
        changes.added.forEach((entity) => {
          if (entity && Object.keys(entity).length > 0) {
            this.storageMap.set(entity.id, new Y.Map(this.getFields(entity)));
          }
        });
        // changes.updated.forEach((entity) => {
        //   const entityMap = this.storageMap.get(entity.id);
        //   this.getFields(entity).forEach(([k, v]) => entityMap.set(k, v));
        //   for (const key of entityMap.keys())
        //     if (entity[key] === null || entity[key] === undefined) entityMap.delete(key);
        // });
        changes.updated.forEach((entity) => {
          if (entity && Object.keys(entity).length > 0) {
            const newEntityMap = new Y.Map(this.getFields(entity));
            this.storageMap.set(entity.id, newEntityMap);
            for (const key of newEntityMap.keys())
              if (entity[key] === null || entity[key] === undefined) newEntityMap.delete(key);
          }
        });
        changes.deleted.forEach(({ id }) => {
          const storedEntity = this.storageMap.has(id);
          if (storedEntity) {
            this.storageMap.delete(id);
          } else {
            logger.error('SingleSourceModelYjsAdapter: entity not found while updating map', id);
          }
        });
      }, this.userActionOrigin);
      //this.model.observer.notify(NotificationTypes.OnAfterSave, changes);
    } catch (e) {
      this.renderTest(changes);
      logger.error(e);
    }
  }

  public serialize(raw = false): string {
    if (raw) return this.getDeScopedData();
    return `adaptor.bootstrap(\`{` + `  "storage": ${this.getDeScopedData()}` + `}\`);`;
  }

  private getDeScopedData() {
    return this.getJsonForYMap(this.storageMap);
  }

  public reset(sure = false) {
    if (isBrowser && !window.location.host.includes('localhost') && !sure && !this.isE2EMode) {
      logger.log('Data for the WHOLE ORGANIZATION will be cleared IN PRODUCTION. If you are sure, call reset(true)');
      return;
    }
    if (isBrowser && !window.location.host.includes('localhost') && sure) {
      if (!confirm("Are you sure sure you want to reset this ENTIRE ORGANIZATION's data?")) return;
    }
    this.storageMap.clear();
    this.modelContext.boundariesIndex.dispose();
    this.modelContext.entityRepository.list().forEach((entity) => this.modelContext.entityRepository.delete(entity.id));
    this.modelContext.graph.clear();
  }

  public bootstrap(data: string, sure = false, direct = false) {
    if (isBrowser && !window.location.host.includes('localhost') && !sure) {
      logger.log('Bootstrap data will be ignored in production. If you are sure, call bootstrap with sure=true');
      return;
    }
    if (isBrowser && !window.location.host.includes('localhost') && sure) {
      if (!confirm('Are you sure sure you want to load this data?')) return;
    }
    const map = JSON.parse(data);
    const storageChanges = {
      added: map.storage,
      updated: [],
      deleted: [],
    };
    this.clearCurrentScope();

    if (direct) {
      this.onSave(storageChanges);
      return;
    }

    this.disabled = true;
    this.applicationState.observer.disable();
    this.onSave(storageChanges);
    this.disabled = false;
    this.applicationState.observer.enable();
    if (isBrowser) window.location.reload();
  }

  private clearCurrentScope() {
    this.modelContext.entityRepository.list().forEach((entity) => this.storageMap.delete(entity.id));
  }

  public saveSetting(key: string, value: any): void {
    this.settingsMap.doc?.transact(() => {
      this.settingsMap.set(key, value);
    }, TransactionType.Local);
  }

  public getSetting(key: string): any {
    return this.settingsMap.get(key);
  }

  private getJsonForYMap(map) {
    return JSON.stringify(Object.values(JSON.parse(JSON.stringify(map.toJSON()))));
  }

  private getFields(obj: any): [string, any][] {
    return JSON.parse(
      JSON.stringify(Object.entries(obj).filter(([key]) => !isTransient(obj, key) && !isLocal(obj, key))),
    );
  }

  private renderTest(changes: { added: any[]; updated: any[]; deleted: any[] }) {
    try {
      const test = `it.only('should replicate the bug', async () => {
    const data = JSON.stringify(${this.getDeScopedData()});
    adaptor['updateMap']({
      added: JSON.parse(data),
      updated: [],
      deleted: [],
    });
    
    adaptor['updateMap'](${JSON.stringify(changes)});
  });`;
      logger.log(test);
    } catch (e) {
      logger.error(`couldn't render a test for scope, entities, changes`, changes);
      logger.error(e);
    }
  }

  public dispose(): void {
    if (this.undoManager) {
      this.undoManager.clear();
      this.undoManager.destroy();
    }
    this.applicationState.observer.unsubscribeByOwner(this.fileId);
    ModelYjsAdaptor.instance = null;
  }

  public fixPositions() {
    const entityRepository = this.modelContext.entityRepository;
    const entityIds = entityRepository.list().map((entity) => entity.id);
    this.applicationState.messageBus.sendInternal(RecalculateDimensionsCommand, { entityIds });
    entityRepository
      .list()
      .filter((e) => e.type === EntityType.Action)
      .map((e) => e.id)
      .forEach((entityId) => {
        this.applicationState.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
        this.applicationState.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
      });
    entityRepository.save();
  }

  private removeNonWorkspaceEntitiesOnceHasRun = false;
  private readonly removeNonWorkspaceEntities: string[] = [];

  private removeNonWorkspaceEntitiesOnce() {
    if (this.removeNonWorkspaceEntitiesOnceHasRun) return;
    this.removeNonWorkspaceEntitiesOnceHasRun = true;
    this.applicationState.observer.disable();
    if (typeof window !== 'undefined') {
      const scopes = JSON.parse(window.localStorage.getItem('workspaces') || '[]');
      scopes.push('*'); // for labels
      logger.log('Cleaning up not-in-scope entities', scopes);
      this.storageMap.doc!.transact(() => {
        this.storageMap.forEach((entityValue, entityKey) => {
          this.removeNonWorkspaceEntities.push(entityKey);
          const entityScopes = entityValue.get('scopes');
          if (!entityScopes || !scopes.includes(entityScopes[0])) this.storageMap.delete(entityKey);
        });
      });
      this.applicationState.observer.enable();
    }
  }
}
