import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { FileType } from '../../data/File.types';
import { ModelFile } from '../../data/ModelFile';
import { FileBase } from '../../data/FileBase';
import { CommandError } from '../../ErrorStore';
import { SpaceContext } from '../../SpaceContext';
import { SpaceFile } from '../../data/SpaceFile';
import { ModelContext } from '../../ModelContext';
import { SetSchemeCommand } from '../apps/SetSchemeCommand';

interface LoadFileCommandParams extends IParams {
  fileId: string;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

interface FileLoadedEventParams extends IParams {
  file: FileBase;
  fileType: FileType;
  fileExtension?: string;
  version?: string;
}

export class FileLoadedEvent extends EventBase {
  static eventType = 'FileLoadedEvent';

  constructor(
    public readonly params: FileLoadedEventParams,
    public readonly source = LoadFileCommand,
  ) {
    super();
  }
}

export class LoadFileCommand extends CommandBase<LoadFileCommandParams> {
  execute(params: LoadFileCommandParams): FileLoadedEvent | CommandError {
    let file: FileBase;
    if (params.fileType === FileType.Model) {
      if (!params.fileExtension) {
        return CommandError.of(new Error('File extension is required for model files'), 'error');
      }
      file = this.appState.fileStoreClient.loadFile<ModelFile>({
        fileId: params.fileId,
        version: params.version,
        type: FileType.Model,
        fileExtension: params.fileExtension,
        onSynced: () => {
          setTimeout(() => {}, 0);
        },
      });
      ModelContext.setActiveModel(file as ModelFile);
      this.appState.messageBus.sendInternal(SetSchemeCommand, {
        fileExtension: params.fileExtension,
        spaceId: 'event-modelling',
      });
    } else {
      file = this.appState.fileStoreClient.loadFile<FileBase>({
        fileId: params.fileId,
        version: params.version,
        type: params.fileType,
        fileExtension: params.fileExtension,
        onSynced: () => {
          setTimeout(() => {}, 0);
        },
      });
      if (params.fileType === FileType.Space) {
        SpaceContext.getInstance().setActiveSpace(file as SpaceFile);
        this.appState.fileTree.clear();
      }
    }
    return new FileLoadedEvent({
      file: file,
      fileType: params.fileType,
      fileExtension: params?.fileExtension,
      version: params.version,
    });
  }
}
