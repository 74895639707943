import { SerializationRule } from 'narrative-studio-sdk/dist/types/scheme/SerializationRule';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Edge } from '../../entities/transitions/Edge';
import { EntityBase } from '../../entities/EntityBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EntityType } from '../../entities/EntityType';

export function toCapitalCase(input: string): string {
  // Split the string by hyphens
  return (
    input
      .replace(/-/g, ' ')
      .split(' ')
      // Capitalize the first letter of each word and make the rest lowercase
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Join the words without any separator
      .join('')
  );
}

const convertToSafeKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    // First normalize ALL whitespace (including newlines) to a single space
    .replace(/\s+/g, ' ')
    // Then replace single spaces with hyphens
    .replace(/ /g, '-')
    // Finally remove all characters except alphanumeric, hyphens, and underscores
    .replace(/[^a-z0-9\-_]/g, '')
    // Clean up any double hyphens that might have been created
    .replace(/-+/g, '-')
    // Remove leading and trailing hyphens and underscores
    .replace(/^[-_]+|[-_]+$/g, '');

const isNullOrEmptyString = (str: string | null | undefined): boolean =>
  str === null || str === undefined || str === '' || str.trim() === '';

const createGetEntityName = () => {
  return (entity: { name?: string }) => {
    return entity.name ? convertToSafeKebabCase(entity.name) : '';
  };
};

function getLaneConfigIndexByType(config: any, type: string): number | null {
  return config.laneGroups.findIndex((laneGroup: any) =>
    laneGroup.allowedEntities?.entities?.some((entity: any) => entity.type === type),
  );
}

function getHydratedLanes(script: ScriptBase, type: EntityType) {
  const eventLaneGroupConfigIndex = getLaneConfigIndexByType(script.config, type) ?? -1;
  const eventLanesIds = script.laneGroups[eventLaneGroupConfigIndex].laneIds;
  const lanes = script.lanes.filter((lane) => eventLanesIds.includes(lane.id));
  return lanes.map((lane) => ({
    id: lane.id,
    label: lane.label,
    entities: script.getEntitiesInLane(script.lanes.findIndex((l) => lane.id === l.id)),
  }));
}

export const narrativeModelSerializationRules: SerializationRule<EntityBase>[] = [
  {
    match: (entity) => entity.type === 'NarrativeDrivenEventModel',
    serialize: (entity: ConstructBase) => {
      const getEntityName = createGetEntityName();
      const eventModelName = getEntityName(entity);
      const eventModelFolder =
        `entities/event-model--` +
        (isNullOrEmptyString(eventModelName) ? 'untitled' : eventModelName) +
        `.${entity.id}/`;

      const rootFiles = [
        {
          entityId: entity.id,
          filename: `${eventModelFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${eventModelFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];

      const aggregateEntries = getHydratedLanes(entity.script, EntityType.Event)
        .map((lane) => [
          {
            entityId: lane.id,
            filename: `${eventModelFolder}shared/aggregate--${convertToSafeKebabCase(lane.label)}.${lane.id}`,
            fileType: `folder`,
          },
          {
            entityId: lane.id,
            filename: `${eventModelFolder}shared/aggregate--${convertToSafeKebabCase(lane.label)}.${
              lane.id
            }/payload.json`,
            fileType: `json`,
            content: JSON.stringify(
              {
                name: toCapitalCase(lane.label),
                entityIds: lane.entities.map((entity) => entity.id),
              },
              null,
              2,
            ),
          },
          {
            entityId: lane.id,
            filename: `${eventModelFolder}shared/aggregate--${convertToSafeKebabCase(lane.label)}.${
              lane.id
            }/metadata.json`,
            fileType: `json`,
            content: JSON.stringify({}, null, 2),
          },
        ])
        .flat();

      const scriptFrameGroups = entity.script.frameGroups.slice(1);

      const slicesFolders = scriptFrameGroups.map((frameGroup, frameGroupIndex) => ({
        entityId: entity.id,
        filename:
          `${eventModelFolder}slice.${frameGroupIndex}--` +
          (isNullOrEmptyString(frameGroup.label) ? 'untitled' : convertToSafeKebabCase(frameGroup.label)) +
          '/',
        fileType: 'folder',
      }));

      const entityFiles = entity.script.frames.flatMap((frame) =>
        frame.entities.flatMap((laneEntities) =>
          laneEntities.flatMap((childEntity) => {
            const entityName =
              `${childEntity.type.toLowerCase()}--` +
              (isNullOrEmptyString(getEntityName(childEntity)) ? 'untitled' : getEntityName(childEntity)) +
              `.${childEntity.id}`;
            const frameGroupIndex = scriptFrameGroups.findIndex((fg) => fg.frameIds.includes(frame.id));
            const frameGroup = scriptFrameGroups[frameGroupIndex];
            const frameGroupLabel =
              `slice.${frameGroupIndex}--` +
              (isNullOrEmptyString(frameGroup.label) ? 'untitled' : convertToSafeKebabCase(frameGroup.label));

            const entityFolder = `${eventModelFolder}${frameGroupLabel}/${entityName}/`;
            return [
              { entityId: childEntity.id, filename: entityFolder, fileType: 'folder' },
              {
                entityId: childEntity.id,
                filename: `${entityFolder}metadata.json`,
                content: JSON.stringify(childEntity.serialize(), null, 2),
                fileType: 'json',
              },
              {
                entityId: childEntity.id,
                filename: `${entityFolder}payload.json`,
                content: JSON.stringify(childEntity.payload ?? {}, null, 2),
                fileType: 'json',
              },
            ];
          }),
        ),
      );

      return [
        { entityId: entity.id, filename: eventModelFolder, fileType: 'folder' },
        ...rootFiles,
        ...slicesFolders,
        ...entityFiles.flat(),
        ...aggregateEntries,
      ];
    },
  },
  {
    match: (entity) => entity instanceof AssetBase,
    serialize: (entity: AssetBase) => {
      const getEntityName = createGetEntityName();
      const assetFolder = `assets/${entity.type.toLowerCase()}--${getEntityName(entity)}/`;

      return [
        { entityId: entity.id, filename: assetFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${assetFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${assetFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
  {
    match: (entity) => entity.type === 'Label',
    serialize: (entity) => {
      const labelFolder = `labels/${entity.name.toLowerCase().replace(/\s+/g, '-')}/`;

      return [
        { entityId: entity.id, filename: labelFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${labelFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${labelFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
  {
    match: (entity) =>
      entity instanceof Edge &&
      entity.source.type.toLowerCase() !== 'NarrativeDrivenEventModel'.toLowerCase() &&
      entity.target.type.toLowerCase() !== 'NarrativeDrivenEventModelScript'.toLowerCase(),
    serialize: (entity: Edge) => {
      const getEntityName = createGetEntityName();
      const sourceName = getEntityName(entity.source);
      const targetName = getEntityName(entity.target);
      const edgeFolder =
        `transitions/${entity.source.type.toLowerCase()}--` +
        (isNullOrEmptyString(sourceName) ? 'untitled' : sourceName) +
        `.${entity.source.id}__${entity.target.type.toLowerCase()}--` +
        (isNullOrEmptyString(targetName) ? 'untitled' : targetName) +
        `.${entity.target.id}/`;
      return [
        { entityId: entity.id, filename: edgeFolder, fileType: 'folder' },
        {
          entityId: entity.id,
          filename: `${edgeFolder}metadata.json`,
          content: JSON.stringify(entity.serialize(), null, 2),
          fileType: 'json',
        },
        {
          entityId: entity.id,
          filename: `${edgeFolder}payload.json`,
          content: JSON.stringify(entity.payload ?? {}, null, 2),
          fileType: 'json',
        },
      ];
    },
  },
];
